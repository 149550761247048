export enum PageTypes {
  account = 'account',
  account_confirm = 'account_confirm',
  account_orders = 'account_orders',
  account_details = 'account_details',
  account_linkedAccounts = 'account_linkedAccounts',
  account_loyalty = 'account_loyalty',
  account_changePassword = 'account_changePassword',
  account_delivery = 'account_delivery',
  account_payment = 'account_payment',
  account_settings = 'account_settings',
  account_notifications = 'account_notifications',
  account_help = 'account_help',
  blog = 'blog',
  blogs = 'blogs',
  cart = 'cart',
  changeEmail = 'change_email',
  content = 'content',
  countrySelect = 'country-select',
  error = 'error',
  family = 'family',
  faq = 'faq',
  fresh = 'fresh',
  contacts = 'contacts',
  giftCard = 'giftCard',
  home = 'home',
  kids = 'kids',
  loading = 'loading',
  lookbook = 'lookbook',
  lottery = 'lottery',
  lottery_success = 'lottery_success',
  lookbookDetail = 'lookbook-detail',
  lookbookList = 'lookbook-list',
  menu = 'menu',
  order_confirmed = 'order_confirmed',
  outlet = 'outlet',
  priceList = 'price_list',
  productsList = 'products',
  productDetail = 'product-detail',
  search = 'search',
  sex = 'sex',
  stores = 'stores',
  wishlist = 'wishlist',
}

export const accountPages: PageTypes[] = [
  PageTypes.account,
  PageTypes.account_orders,
  PageTypes.account_details,
  PageTypes.account_linkedAccounts,
  PageTypes.account_changePassword,
  PageTypes.account_delivery,
  PageTypes.account_payment,
  PageTypes.account_notifications,
  PageTypes.account_help,
];

export const giftCardProductCategory = 'e_giftcard';

export const productsPageTypesClear: PageTypes[] = [PageTypes.productsList, PageTypes.productDetail, PageTypes.outlet, PageTypes.fresh, PageTypes.kids];
export const productsPageTypes: PageTypes[] = [...productsPageTypesClear, PageTypes.menu];

export const productsListTypes: PageTypes[] = [PageTypes.productsList, PageTypes.fresh, PageTypes.outlet, PageTypes.kids];
