export const CUSTOM_BANNERS_TYPE_BANNER = 'banner';
export const CUSTOM_BANNERS_TYPE_NOTIFICATION = 'notification';
export type CUSTOM_BANNERS_TYPE_BANNER = 'banner';
export type CUSTOM_BANNERS_TYPE_NOTIFICATION = 'notification';
export const CUSTOM_BANNERS_TYPE_TOP_CAMPAIGN = 'top_campaign';
export type CUSTOM_BANNERS_TYPE_TOP_CAMPAIGN = 'top_campaign';

export const CUSTOM_BANNERS_TYPE_SALE_TAG = 'saletag';
export type CUSTOM_BANNERS_TYPE_SALE_TAG = 'saletag';

export type CUSTOM_BANNERS_CLOSE = 'CUSTOM_BANNERS_CLOSE';
export const CUSTOM_BANNERS_CLOSE = 'CUSTOM_BANNERS_CLOSE';
