import cookie from 'js-cookie';
import { ActionCreator } from 'redux';

import * as actions from '@constants/actions/page';
import { listSizeBig } from '@constants/index';
import { PageTypes } from '@constants/pageTypes';
import {
  IPageChangeAction,
  IPageChatTawkAction,
  IPageChatTawkLoadingAction,
  IPageCountrySelectAction,
  IPageFirstRunStateAction,
  IPageMobileMenuStateChangeAction,
  IPageMobileUpdateAction,
  IPageSessionStateAction,
  IPageSideMenuPageAction,
  IPageSideMenuStateAction,
  IPageUnmountAction,
} from '@interfaces/page/actions';
import { ESideMenuPage, IMobileMenuState } from '@interfaces/page/store';

export const acPageSideMenuStateAction: (state: boolean) => IPageSideMenuStateAction = (state) => ({
  payload: state,
  type: actions.PAGE_SIDEMENU_STATE,
});

export const acPageSideMenuPageAction: (page: ESideMenuPage) => IPageSideMenuPageAction = (page) => ({
  payload: page,
  type: actions.PAGE_SIDEMENU_PAGE,
});

export const acPageChangeListSizeAction: (state: boolean) => IPageChangeAction = (state) => ({
  payload: state,
  type: actions.PAGE_CHANGE_SIZE,
});

export const acPageCountrySelectAction: (state: boolean) => IPageCountrySelectAction = (state) => ({
  payload: state,
  type: actions.PAGE_COUNTRY_SELECT,
});

export const acPageChangeListSize = (state: boolean) => (dispatch) => {
  cookie.set(listSizeBig, state);
  return dispatch(acPageChangeListSizeAction(state));
};

export const acPageMobileUpdate: ActionCreator<IPageMobileUpdateAction> = (state: boolean) => ({
  payload: state,
  type: actions.PAGE_MOBILE_UPDATE,
});

export const acPageUnmount: ActionCreator<IPageUnmountAction> = (page: PageTypes) => ({
  payload: page,
  type: actions.PAGE_UNMOUNT,
});

export const acFirstRunState: ActionCreator<IPageFirstRunStateAction> = (state: boolean) => ({
  payload: state,
  type: actions.PAGE_FIRSTRUN_STATE,
});

export const acPageSessionState: ActionCreator<IPageSessionStateAction> = (state: boolean) => ({
  payload: state,
  type: actions.PAGE_SESSION_STATE,
});

type TMobileMenuStateChangeAction =  (state: Partial<IMobileMenuState>) => IPageMobileMenuStateChangeAction;
export const acMobileMenuChangeState: TMobileMenuStateChangeAction = (state) => ({
  payload: state,
  type: actions.MOBILE_MENU_STATE,
});

export const acChatTawkInit: ActionCreator<IPageChatTawkAction> = () => ({
  type: actions.PAGE_CHAT_INIT,
});

export const acChatTawkLoading: ActionCreator<IPageChatTawkLoadingAction> = (state) => ({
  payload: state,
  type: actions.PAGE_CHAT_LOADING,
});
