import { previewMode } from '@constants/config';
import { IHomePageComponentDataObject } from '@interfaces/home';

const enabledHosts = ['preview.denimdream.com'];

export const isPreviewHost = (host?: string) => !!host && enabledHosts.includes(host);

export const isPreviewModeEnabled = (host?: string) => {
  return previewMode || isPreviewHost(host);
};

export const checkPreviewMode = (component: IHomePageComponentDataObject, host?: string) => {
  return component.previewmode === false || isPreviewModeEnabled(host) === component.previewmode;
};
