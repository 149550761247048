export enum ESideMenuPage {
  login = 'login',
  loginSmartId = 'loginSmartId',
  forgot = 'forgot',
  newPassword = 'newPassword',
  newPasswordExpired = 'newPasswordExpired',
  newPasswordSuccess = 'newPasswordSuccess',
  welcomeBack = 'welcomeBack',
  cart = 'cart',
  registration = 'registration',
  registrationSuccessful = 'registrationSuccessful',
  main = 'main',
  availability = 'availability',
  createdAndNeedConfirm = 'createdAndNeedConfirm',
  resendActivation = 'resendActivation'
}

export interface IPageStore {
  chatTawk: number;
  chatTawkLoading: boolean;
  countrySelect: boolean;
  firstRun: boolean;
  listSizeBig: boolean;
  sessionLoading: boolean;
  sideMenu: boolean;
  sideMenuPage: ESideMenuPage;
  mobileMenu: IMobileMenuState;
  mobilePageUpdate: boolean;
  userMenu: boolean;
}

export interface IMobileMenuState {
  category: number | null;
  gender: number | null;
  open: boolean;
  preventInit?: boolean;
  scroll?: number | null;
  tab: number | null;
}
