import Head from 'next/head';
import React from 'react';
import { useSelector } from 'react-redux';
import { IResult } from 'ua-parser-js';

import { mobileMode } from '@constants/config';
import { homePageComponentsTypes } from '@constants/homePageComponents';
import { getMediaUrl } from '@constants/urls';
import { IHomePageComponentDataObject } from '@interfaces/home';
import { IStore } from '@interfaces/store';


const HomePageSliderImageToPriority: React.FunctionComponent<{}> = () => {

  const pageDataComponents = useSelector((state: IStore) => state.pageData.data.components);
  const userAgent: Partial<IResult> | null = useSelector((state: IStore) => state.context.userAgent);
  const initialDeviceSize = userAgent?.device?.type && ['tablet', 'mobile'].includes(userAgent.device.type) ? 'sm' : 'lg';

  const filterBySize = (componentToFilter: IHomePageComponentDataObject) => {
    return initialDeviceSize === 'sm'
      ? (mobileMode ? componentToFilter.showInApp : componentToFilter.showInMobile)
      : componentToFilter.show;
  };

  const component = pageDataComponents?.filter(filterBySize).find((componentItem) => componentItem.type === homePageComponentsTypes.COMPONENT_TYPE_SLIDER);
  if (component?.media) {
    if (typeof component.media === 'object') {
      const media = component?.media as string[];
      return (
        <Head>
          {media.map((mediaItem, key) =>
            <link
              rel="preload"
              href={getMediaUrl(mediaItem || '')}
              as="image"
              key={`HomePageSliderPreload${key}`}
            />,
          )}
        </Head>
      );
    } else if (typeof component.media === 'string') {
      return (<Head>
        <link
          rel="preload"
          href={getMediaUrl(component.media || '')}
          as="image"
        />
      </Head>);
    }
  }
  return null;
};

export default HomePageSliderImageToPriority;
