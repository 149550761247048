export const PAGE_SIDEMENU_STATE = 'PAGE_SIDEMENU_STATE';
export type PAGE_SIDEMENU_STATE = 'PAGE_SIDEMENU_STATE';

export const PAGE_SIDEMENU_PAGE = 'PAGE_SIDEMENU_PAGE';
export type PAGE_SIDEMENU_PAGE = 'PAGE_SIDEMENU_PAGE';

export const PAGE_CHANGE_SIZE = 'PAGE_CHANGE_SIZE';
export type PAGE_CHANGE_SIZE = 'PAGE_CHANGE_SIZE';

export const PAGE_COUNTRY_SELECT = 'PAGE_COUNTRY_SELECT';
export type PAGE_COUNTRY_SELECT = 'PAGE_COUNTRY_SELECT';

export const MOBILE_MENU_STATE = 'MOBILE_MENU_STATE';
export type MOBILE_MENU_STATE = 'MOBILE_MENU_STATE';

export const PAGE_MOBILE_UPDATE = 'PAGE_MOBILE_UPDATE';
export type PAGE_MOBILE_UPDATE = 'PAGE_MOBILE_UPDATE';

export const PAGE_UNMOUNT = 'PAGE_UNMOUNT';
export type PAGE_UNMOUNT = 'PAGE_UNMOUNT';

export const PAGE_FIRSTRUN_STATE = 'PAGE_FIRSTRUN_STATE';
export type PAGE_FIRSTRUN_STATE = 'PAGE_FIRSTRUN_STATE';

export const PAGE_TRACKING_STATE = 'PAGE_TRACKING_STATE';
export type PAGE_TRACKING_STATE = 'PAGE_TRACKING_STATE';

export const PAGE_SESSION_STATE = 'PAGE_SESSION_STATE';
export type PAGE_SESSION_STATE = 'PAGE_SESSION_STATE';

export const PAGE_NATIVE_SETTINGS_SET = 'PAGE_NATIVE_SETTINGS_SET';
export type PAGE_NATIVE_SETTINGS_SET = 'PAGE_NATIVE_SETTINGS_SET';

export const PAGE_CHAT_INIT = 'PAGE_CHAT_INIT';
export type PAGE_CHAT_INIT = 'PAGE_CHAT_INIT';

export const PAGE_CHAT_LOADING = 'PAGE_CHAT_LOADING';
export type PAGE_CHAT_LOADING = 'PAGE_CHAT_LOADING';
