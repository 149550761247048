import { Reducer } from 'redux';

import { IPriceListStore } from '@interfaces/priceList/reducer';
import { IPriceListActions } from '@interfaces/priceList/actions';
import * as actions from '../../constants/actions/priceList';
import { initialState } from './initState';

export const priceListReducer: Reducer<IPriceListStore, IPriceListActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.PRICE_LIST_GET_REQUEST:
      return { ...state, loading: true };
    case actions.PRICE_LIST_GET_SUCCESS:
      return { ...state, loading: false, list: action.payload?.data?.attributes?.categories || [] };
    case actions.PRICE_LIST_GET_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
